<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="wallet">
      <b-modal
          id="modal-deposit"
          cancelTitle="Cancel"
          centered
          ok-title="Submit"
          title="Deposit Into User's Wallet"
          @ok="deposit"
      >
        <div class="row">
          <div class="col-md-12">
            <b-form-group
                label="Amount In $"
            >
              <b-form-input
                  v-model.trim="creditAmount"
                  name="public-key"
                  placeholder="$"
              />
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
                label="description"
            >
              <b-form-input
                  v-model.trim="description"
                  name="public-key"
                  placeholder="description..."
              />
            </b-form-group>
          </div>
        </div>
      </b-modal>
      <b-modal
          id="modal-withdraw"
          cancelTitle="Cancel"
          centered
          ok-title="Submit"
          title="Withdraw From User's Wallet"

          @ok="withdraw"
      >
        <div class="row">
          <div class="col-md-12">
            <b-form-group
                label="Amount In $"
            >
              <b-form-input
                  v-model.trim="creditAmount"
                  name="public-key"
                  placeholder="$"
              />
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
                label="description"
            >
              <b-form-input
                  v-model.trim="description"
                  name="public-key"
                  placeholder="description..."
              />
            </b-form-group>
          </div>
        </div>
      </b-modal>

      <!-- Table Container Card -->
      <b-card
          class="my-0"
      >
        <div class="card card-statistics">
          <div class="card-header p-0"><h4 class="card-title">Wallet</h4>
            <p class="card-text font-small-2 mr-25 mb-0"> Updated
              {{ new Date(wallet.updatedAt).toLocaleDateString('en-US') }} </p></div>
          <div class="card-body pt-2 pb-0 px-0 ">
            <div class="row">
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="media-aside mr-2 align-self-start"><span
                      class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"><svg class="feather feather-trending-up" fill="none" height="24px"
                                                      stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2" viewBox="0 0 24 24" width="24px"
                                                      xmlns="http://www.w3.org/2000/svg"
                  ><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"
                  ></polyline></svg></span><!----></span></div>
                  <div class="media-body my-auto"><h4 class="font-weight-bolder mb-0"> {{
                      Intl.NumberFormat('en-US')
                          .format(wallet.fundInHold)
                    }} $ </h4>
                    <p class="card-text font-small-3 mb-0">Funds In Hold</p></div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="media-aside mr-2 align-self-start"><span class="b-avatar badge-light-info rounded-circle"
                                                                       style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"><svg class="feather feather-user" fill="none" height="24px"
                                                      stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2" viewBox="0 0 24 24" width="24px"
                                                      xmlns="http://www.w3.org/2000/svg"
                  ><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"
                  ></circle></svg></span><!----></span></div>
                  <div class="media-body my-auto"><h4 class="font-weight-bolder mb-0">{{
                      Intl.NumberFormat('en-US')
                          .format(wallet.cashBack)
                    }} $ </h4>
                    <p class="card-text font-small-3 mb-0"> Cashback </p></div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="media">
                  <div class="media-aside mr-2 align-self-start"><span
                      class="b-avatar badge-light-success rounded-circle" style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"><svg class="feather feather-dollar-sign" fill="none" height="24px"
                                                      stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2" viewBox="0 0 24 24" width="24px"
                                                      xmlns="http://www.w3.org/2000/svg"
                  ><line x1="12" x2="12" y1="1" y2="23"></line><path
                      d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                  ></path></svg></span><!----></span></div>
                  <div class="media-body my-auto"><h4 class="font-weight-bolder mb-0"> {{
                      Intl.NumberFormat('en-US')
                          .format(wallet.balance
                          )
                    }} $ </h4>
                    <p class="card-text font-small-3 mb-0"> Current Balance </p></div>
                </div>
                <div class="p-1 d-flex align-items-center ">
                  <button v-b-modal.modal-deposit class="btn bg-success text-white mx-25" type="button">Deposit</button>
                  <button v-b-modal.modal-withdraw class="btn bg-danger text-white mx-25" type="button">Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div><!----><!----></div>
      </b-card>
      <b-card
          class="my-1"
      >
        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="walletTransaction"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            sort-by.sync
            striped
        >
          <template #cell(amount)="data">
            <p>{{
                Intl.NumberFormat('en-US')
                    .format(data.item.amount)
              }} $</p>
          </template>
          <template #cell(action)="data">
            <b-badge
                v-if="data.item.action==='Deposit'"
                pill
                variant="success"
            >
              Deposit
            </b-badge>
            <b-badge
                v-else
                pill
                variant="danger"
            >
              Withdraw
            </b-badge>
          </template>
          <template #cell(transactionStatus)="data">

            <b-badge

                pill
                variant="primary"
            >
              {{ data.item.transactionStatus }}
            </b-badge>
          </template>
          <template #cell(isDone)="data">

            <feather-icon v-if="data.item.isDone" class="text-success" icon="CheckIcon"></feather-icon>
            <feather-icon v-else class="text-danger" icon="XIcon"></feather-icon>
          </template>
          <template #cell(cashBack)="data">
            <p>{{ data.item.cashBack }} $</p>
          </template>
          <template #cell(createdAt)="data">
            <p>{{ new Date(data.item.createdAt).toLocaleDateString('en-US') }} - {{new Date(data.item.createdAt).toLocaleTimeString('en-US') }}</p>
          </template>
          <template #cell(operation)="data">
            <b-link
                :to="{ name: 'apps-wallets-wallet-info', params: { id: data.item.walletId } }"
                class="font-weight-bold"
            >
              <feather-icon class="text-primary ml-1" icon="EyeIcon" size="20"></feather-icon>

            </b-link>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

    </div>
  </b-overlay>

</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Debouncer from '@/views/components/debouncer.vue'
import { WalletDeposit, WalletGetRequest, WalletGetTransactionsForWallet, WalletWithdraw } from '@/libs/Api/Wallet'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default {
  name: 'WalletInfo',
  components: {
    Debouncer,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      wallet: null,
      walletTransaction: null,
      showOverlay: false,
      totalCount: null,
      perPage: 10,
      description: '',
      creditAmount: null,
      currentPage: 1,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'action',
          label: 'action',
          sortable: true
        },
        {
          key: 'amount',
          label: 'amount',
          sortable: true
        },
        {
          key: 'isDone',
          label: 'completed',
          sortable: true
        },
        {
          key: 'transactionStatus',
          label: 'status',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Create Date',
          sortable: true
        },
        {
          key: 'description',
          label: 'description'
        },

      ],

    }
  },
  created() {
    this.getWalletInfo()
  },
  watch: {
    currentPage: function (val) {
      this.getWalletTransactions()
    }
  },
  methods: {
    async withdraw() {
      let _this = this
      if (_this.description && _this.creditAmount) {
        _this.showOverlay = true
        let walletWithdraw = new WalletWithdraw(_this)
        let data = {
          description: _this.description,
          credit: _this.creditAmount,
          userId: _this.wallet.userId
        }
        walletWithdraw.setParams(data)
        await walletWithdraw.fetch(function (content) {
          _this.getWalletTransactions()
          _this.description = ''
          _this.creditAmount = null
          _this.getWalletInfo()
          _this.showOverlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: 'Withdraw Was Successful',
              title: `success`,
              variant: 'success',
            },
          })
        }, function (error) {
          _this.description = ''
          _this.creditAmount = null
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'Please Fill In All The Fields',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async deposit() {
      let _this = this
      if (_this.description && _this.creditAmount) {
        _this.showOverlay = true
        let walletDeposit = new WalletDeposit(_this)
        let data = {
          description: _this.description,
          credit: _this.creditAmount,
          userId:_this.wallet.userId
        }
        walletDeposit.setParams(data)
        await walletDeposit.fetch(function (content) {
          _this.getWalletTransactions()
          _this.description = ''
          _this.creditAmount = null
          _this.getWalletInfo()
          _this.showOverlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: 'Deposit Was Successful',
              title: `success`,
              variant: 'success',
            },
          })
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.description = ''
          _this.creditAmount = null
          _this.showOverlay = false
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'Please Fill In All The Fields',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async getWalletInfo() {
      let _this = this
      _this.showOverlay = true
      let walletGetRequest = new WalletGetRequest(_this)
      let data = {
        walletId: _this.$route.params.id,

      }
      walletGetRequest.setParams(data)
      await walletGetRequest.fetch(function (content) {
        _this.wallet = content.data
        _this.getWalletTransactions()
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getWalletTransactions() {
      let _this = this
      _this.showOverlay = true
      let walletGetTransactionsForWallet = new WalletGetTransactionsForWallet(_this)
      let data = {
        userId: _this.wallet.userId,
        page: _this.currentPage,
        size: _this.perPage
      }
      walletGetTransactionsForWallet.setParams(data)
      await walletGetTransactionsForWallet.fetch(function (content) {
        _this.walletTransaction = content.data.data.data
        _this.totalCount = content.data.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    }
  }
}
</script>

<style scoped>

</style>
